<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts' // echarts theme
import { countSexTypeData } from '@/api/count'
require('echarts/theme/macarons')

export default {
  props: {
    schData: {
      type: Object
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '280px'
    }
  },
  data () {
    return {
      chart: null,
      sexCount: [],
      timer: null
    }
  },
  watch: {
    'schData.grade': function (grade) {
      this.countSexTypeData({ grade })
    },
    'schData.timeToRefresh': function (timeToRefresh) {
      if (timeToRefresh) {
        this.setTimeHandle()
      } else {
        clearInterval(this.timer)
      }
    }
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    clearInterval(this.timer)
  },
  methods: {
    // 统计男女 饼状图 数据
    countSexTypeData (params) {
      countSexTypeData(params).then(res => {
        this.sexCount = res.data
        this.initChart()
      })
    },
    setTimeHandle () {
      this.timer = setInterval(() => {
        this.countSexTypeData({ grade: this.schData.grade })
      }, 5 * 1000)
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')

      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['男生', '女生']
        },
        series: [
          {
            name: 'WEEKLY WRITE ARTICLES',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '50%'],
            data: this.sexCount,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      })
    }
  }
}
</script>
