<!--首页：统计信息-->
<template>
  <div class="statistics-page">
    <el-row>
      <el-col :span="12" style="padding-top:20px;">
        <header-select
          :selectItem="['grade']"
          @change="selectChangeHandle"
          @search="searchSelectHandle"
          :isSearchButton="false"
        ></header-select>
      </el-col>
      <div style="padding-top:20px;float: right">
        <el-switch
          v-permission="['home:auto:refresh']"
          v-model="timeToRefresh"
          @change="handleTimeToRefresh"
          active-color="var(--color-success)"
          active-text="自动刷新"
          inactive-text="关闭刷新" />
      </div>
    </el-row>
    <PanelGroup :schData="schData" />
    <el-row style="background: #fff; padding: 16px 16px 0; margin-bottom: 32px">
      <majorChartBar :schData="schData" />
    </el-row>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="chart-wrapper">
          <sexPieChart :schData="schData" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="chart-wrapper">
          <collegeChartBar :schData="schData" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PanelGroup from './panelGroup.vue'
import majorChartBar from '@/components/echarts/majorChartBar'
import collegeChartBar from '@/components/echarts/collegeChartBar'
import sexPieChart from '@/components/echarts/sexPieChart'

export default {
  name: 'StatisticsPage',
  components: {
    PanelGroup,
    majorChartBar,
    collegeChartBar,
    sexPieChart
  },
  data() {
    return {
      schData: {
        timeToRefresh: false
      },
      timeToRefresh: false,
      timer: null
    }
  },
  created() {
    this.searchSelectHandle()
  },
  methods: {
    handleTimeToRefresh() {
      this.schData = {
        grade: this.schData.grade,
        timeToRefresh: this.timeToRefresh
      }
    },
    // 下拉选择事件
    selectChangeHandle(data) {
      if (data != null) {
        data.timeToRefresh = this.timeToRefresh
      }
      this.schData = data
    },
    // 搜索事件
    searchSelectHandle(data) {
      if (data != null) {
        data.timeToRefresh = this.timeToRefresh
      }
      this.schData = data
    }
  }
}
</script>

<style lang="scss">
.statistics-page {
  box-sizing: border-box;
  height: auto;
  padding: 0 20px;
  background-color: rgb(240, 242, 245);

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
</style>
