import request from '@/service/request'

// 专升本意向管理分页查询
const getSchBachelorInfo = (params) => {
  return request.post('/sch/schBachelorIntention/page', params)
}

// 根据Id查询
const getSchBachelorInfoById = (params) => {
  return request.post('/sch/schBachelorIntention/getById/' + params)
}

// 获取所有意向专业 list
export const listSchBachelorInfoApi = (params) => {
  return request.post('/sch/schBachelorIntention/list', params)
}

// 专升本意向新增保存
const schBachelorInfoSave = (params) => {
  return request.post('/sch/schBachelorIntention/save', params)
}

// 删除专升本意向
const deleteSchBachelorInfo = (params) => {
  return request.post('/sch/schBachelorIntention/del/' + params)
}

// 专升本意向统计
const getHigherSchoolCount = (params) => {
  return request.post('/stu/stuInfo/countBachelorIntention', params)
}

// 统计全校录取总人数、已缴费人数、已报到人数、报到率
const countIndexSchoolData = (params) => {
  return request.post('/stu/stuInfo/countIndexSchoolData', params)
}

// 统计缴费人数 柱状图数据
const countPayData = (params) => {
  return request.post('/stu/stuInfo/countPayData', params)
}

// 统计男女 饼状图 数据
const countSexTypeData = (params) => {
  return request.post('/stu/stuInfo/countSexTypeData', params)
}

// 统计报到人数 柱状图 数据
const countSignInData = (params) => {
  return request.post('/stu/stuInfo/countSignInData', params)
}

export {
  getSchBachelorInfo,
  getSchBachelorInfoById,
  schBachelorInfoSave,
  deleteSchBachelorInfo,
  getHigherSchoolCount,
  countIndexSchoolData,
  countPayData,
  countSexTypeData,
  countSignInData
}
