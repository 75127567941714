<template>
  <StatisticsPage v-if="isStatistician"/>
  <WelcomePage v-else/>
</template>

<script>
import { hasPermission } from '@/utils/common-js'
import StatisticsPage from '@/views/pages/index/components/StatisticsPage.vue'
import WelcomePage from '@/views/pages/index/components/WelcomePage.vue'

export default {
  components: {
    WelcomePage,
    StatisticsPage
  },
  data() {
    return {
      isStatistician: false
    }
  },
  mounted() {
    this.isStatistician = hasPermission('home:statistician')
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  box-sizing: border-box;
  height: auto;
  padding: 0 20px;
  background-color: rgb(240, 242, 245);

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
</style>
