<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('newVisitis')">
        <div class="card-panel-icon-wrapper icon-people">
          <i class="el-icon-s-custom" style="font-size: 50px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            全校录取总人数
          </div>
          <count-to :start-val="0" :end-val="count.allNum" :duration="2600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('messages')">
        <div class="card-panel-icon-wrapper icon-message">
          <i class="el-icon-s-custom" style="font-size: 50px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            <!--  已缴费人数包括：<br/>1.在校<br/>2.未报到（已缴费）<br/>3.退学（已缴费）<br/>4.休学（已缴费）<br/>的学生。 -->
            已缴费人数
          </div>
          <count-to :start-val="0" :end-val="count.payNum" :duration="3000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('purchases')">
        <div class="card-panel-icon-wrapper icon-money">
          <i class="el-icon-s-custom" style="font-size: 50px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            已报到人数
          </div>
          <count-to :start-val="0" :end-val="count.signNum" :duration="3200" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shoppings')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <i class="el-icon-s-custom" style="font-size: 50px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            报到率
          </div>
          <count-to :start-val="0" :end-val="count.signRate" suffix="%" :duration="3600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'
import { countIndexSchoolData } from '@/api/count'

export default {
  components: {
    CountTo
  },
  props: {
    schData: {
      type: Object
    }
  },
  data () {
    return {
      count: {},
      timer: null
    }
  },
  watch: {
    'schData.grade': function (grade) {
      this.countIndexSchoolData({ grade })
    },
    'schData.timeToRefresh': function (timeToRefresh) {
      if (timeToRefresh) {
        this.setTimeHandle()
      } else {
        clearInterval(this.timer)
      }
    }
  },
  // 监听路由销毁
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    // 获取统计
    countIndexSchoolData (params) {
      countIndexSchoolData(params).then(res => {
        this.count = res.data
      })
    },
    setTimeHandle () {
      this.timer = setInterval(() => {
        this.countIndexSchoolData({ grade: this.schData.grade })
      }, 5 * 1000)
    },
    handleSetLineChartData (type) {
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-group {

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    border-radius: 4px;

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
