<template>
  <div class="welcome-page">
    <div class="welcome-page_contain flex">
      <div class="main">
        <h1 class="title">欢迎登录广东南方职业学院智慧校园管理系统</h1>
        <div class="sub-title">Welcome to the Smart Campus Management System of GuangDong NanFang Institute of Technology
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage'
}
</script>

<style lang="scss">
.welcome-page {
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - var(--header-height));
  padding: 2.5vh 3vw 5vh;
  background: #eef0f3;

  .welcome-page_contain {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff url("~@/assets/image/home/home-bg_bottom.png") no-repeat center 101%;
    background-size: contain;
    border-radius: 2px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .05);

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("~@/assets/image/home/home-bg_left.png") no-repeat -5px 40%;
      content: '';
      z-index: 0;
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("~@/assets/image/home/home-bg_right.png") no-repeat right top;
      content: '';
      z-index: 0;
    }

    .main {
      box-sizing: border-box;
      margin-top: 17%;
      padding: 0 5rem;
      text-align: center;
      z-index: 9;

      .title {
        font-size: 46px;
        color: #333333;
      }

      .sub-title {
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
