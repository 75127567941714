<template>
  <div style="width: 85vw; height: 300px" id="main"></div>
</template>

<script>
import * as echarts from 'echarts'
import { countPayData } from '@/api/count'

export default {
  name: 'echarts',
  props: {
    schData: {
      type: Object
    }
  },
  data () {
    return {
      majorCount: {},
      timer: null
    }
  },
  computed: {
    majorList () {
      const list = []
      for (let i = 0; i < 40; i++) {
        list.push('专业专业' + (i + 1))
      }
      return list
    }
  },
  watch: {
    'schData.grade': function (grade) {
      this.countPayData({ grade })
    },
    'schData.timeToRefresh': function (timeToRefresh) {
      if (timeToRefresh) {
        this.setTimeHandle()
      } else {
        clearInterval(this.timer)
      }
    }
  },
  // 监听路由销毁
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    // 统计缴费人数 柱状图数据
    countPayData (params) {
      countPayData(params).then(res => {
        this.majorCount = res.data
        this.echartsInit()
      })
    },
    setTimeHandle () {
      this.timer = setInterval(() => {
        this.countPayData({ grade: this.schData.grade })
      }, 5 * 1000)
    },
    echartsInit () {
      // 使用时只需要把setOption里的对象换成echarts中的options或者自己的参数即可
      echarts.init(document.getElementById('main')).setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        grid: {
          y2: 120
        },
        xAxis: {
          type: 'category',
          data: this.majorCount.xaxisData,
          axisLabel: {
            interval: 0,
            rotate: -60
          }
        },
        yAxis: {
          type: 'value',
          name: '缴费人数'
        },
        series: [
          {
            data: this.majorCount.seriesData,
            type: 'bar',
            barWidth: '10',
            itemStyle: {
              normal: {
                label: {
                  show: true, // 是否显示
                  position: 'top', // 显示在顶部
                  textStyle: {
                    color: 'black', // 字体颜色
                    fontSize: 10 // 字体大小
                  }
                }
              }
            }
          }
        ]
      })
    }
  }
}
</script>
